:root {
	--alert-soft-error-background: #FFF5F6;
	--alert-soft-error-content: #6C0000;
	--alert-soft-error-icon-background-hover: #EECACE;
	--alert-soft-error-outline: #D0021B;
	--alert-soft-info-background: #F0FAFF;
	--alert-soft-info-content: #003A7C;
	--alert-soft-info-icon-background-hover: #D2E9F5;
	--alert-soft-info-outline: #2D9EE0;
	--alert-soft-success-background: #F4FAF2;
	--alert-soft-success-content: #224700;
	--alert-soft-success-icon-background-hover: #D5E9CF;
	--alert-soft-success-outline: #6ABF14;
	--alert-soft-warning-background: #FDF7EA;
	--alert-soft-warning-content: #612800;
	--alert-soft-warning-icon-background-hover: #E9D8B3;
	--alert-soft-warning-outline: #EDB431;
	--alert-vivid-content: #FFFFFF;
	--alert-vivid-error-background: #D0021B;
	--alert-vivid-generic-background: #1C2027;
	--alert-vivid-generic-content: #FFFFFF;
	--alert-vivid-info-background: #14AAF5;
	--alert-vivid-success-background: #60B514;
	--alert-vivid-warning-background: #EB6C00;
	--background-mention-of-you-notification: #F6F5FF;
	--background-new-activity: #F0FDFF;
	--background-primary: #FFFFFF;
	--background-primary-base-1-active: #F4F4F4;
	--background-primary-base-1-disabled: #F7F7F7;
	--background-primary-base-1-hover: #F8F8F8;
	--background-primary-comment-1-active: #FFFBF3;
	--background-primary-inverted: #14AAF5;
	--background-secondary: #E6E6E6;
	--background-secondary-base-1-hover: #F2F2F2;
	--background-selected: #EFF7FF;
	--base-icon-disabled: #CCCCCC;
	--base-icon-primary-active: #14A6F5;
	--base-icon-secondary: #989998;
	--base-icon-secondary-hover: #000000;
	--base-text-code: #DD1144;
	--base-text-green: #60B503;
	--base-text-inverted-primary: #FFFFFF;
	--base-text-inverted-secondary: rgba(255, 255, 255, 0.7);
	--base-text-link: #007BFF;
	--base-text-mention-current-user: #4200FF;
	--base-text-mention-no-access: #5C5C5C;
	--base-text-orange: #FF800B;
	--base-text-primary: #000000;
	--base-text-purple: #8F00FF;
	--base-text-red: #D0021B;
	--base-text-secondary: #757575;
	--base-text-secondary-hover: #000000;
	--base-text-text-placeholder: #B2B2B2;
	--button-danger-background: #D83A00;
	--button-danger-background-active: #BD3300;
	--button-danger-background-hover: #CC3700;
	--button-danger-content-primary: #FFFFFF;
	--button-danger-content-secondary: #FFFFFF;
	--button-danger-outline: rgba(0, 0, 0, 0);
	--button-disabled-background: #E6E6E6;
	--button-disabled-content: #5A5A5A;
	--button-formatting-background-hover: #F5F5F5;
	--button-icon-background: rgba(0, 0, 0, 0);
	--button-icon-background-active: rgba(0, 0, 0, 0.15);
	--button-icon-background-hover: rgba(0, 0, 0, 0.08);
	--button-icon-content: #989998;
	--button-icon-content-hover: #292929;
	--button-primary-background: #FFFFFF;
	--button-primary-background-active: #F2F2F2;
	--button-primary-background-hover: #FAFAFA;
	--button-primary-content-primary: #292929;
	--button-primary-content-secondary: #989998;
	--button-primary-outline: #CCCCCC;
	--button-secondary-background: #14AAF5;
	--button-secondary-background-active: #13A1E8;
	--button-secondary-background-hover: #18A8F0;
	--button-secondary-content-primary: #FFFFFF;
	--button-secondary-content-secondary: #FFFFFF;
	--button-secondary-outline: rgba(0, 0, 0, 0);
	--button-upgrade-background: linear-gradient(180deg, #FFA100 0%, #FF9200 100%);
	--button-upgrade-background-active: linear-gradient(180deg, #E69100 0%, #E68300 100%);
	--button-upgrade-background-hover: linear-gradient(180deg, #F59A00 0%, #F58C00 100%);
	--button-upgrade-content-primary: #FFFFFF;
	--button-upgrade-outline: rgba(241, 143, 0, 0);
	--button-warning-background: #F18F00;
	--button-warning-background-active: #D67F00;
	--button-warning-background-hover: #E68800;
	--button-warning-content-primary: #FFFFFF;
	--button-warning-content-secondary: #FFFFFF;
	--button-warning-outline: rgba(0, 0, 0, 0);
	--checkbox-check: #FFFFFF;
	--checkbox-off-background: rgba(255, 255, 255, 0);
	--checkbox-off-outline: #999999;
	--checkbox-on-background: #1EABEB;
	--checkbox-on-outline: #1EABEB;
	--chip-unread-background: #ED0000;
	--chip-unread-content: #FFFFFF;
	--chip-unread-outline: rgba(0, 0, 0, 0);
	--diff-checker-highlight-addition: #D9FFDF;
	--diff-checker-highlight-removal: #FFE6E6;
	--drop-shadow-os-window-shadow: 0 2px 35px rgba(0, 0, 0, 0.3);
	--drop-shadow-outlined-shadow-10: 0 0 1px rgba(0, 0, 0, 0.4), 0 15px 80px rgba(0, 0, 0, 0.07), 0 8.128817558288574px 12.038336753845215px rgba(0, 0, 0, 0.07), 0 4.534512519836426px 6.44888162612915px rgba(0, 0, 0, 0.07), 0 2.1927247047424316px 3.740891456604004px rgba(0, 0, 0, 0.067), 0 0.7418401837348938px 1.7855631113052368px rgba(0, 0, 0, 0.057);
	--drop-shadow-outlined-shadow-20: 0 0 1px rgba(0, 0, 0, 0.4), 0 27px 42px rgba(0, 0, 0, 0.08), 0 9.27587890625px 19.785350799560547px rgba(0, 0, 0, 0.076), 0 4.723095893859863px 11.670555114746094px rgba(0, 0, 0, 0.069), 0 2.2491250038146973px 6.129580497741699px rgba(0, 0, 0, 0.058), 0 0.8091866970062256px 2.203927993774414px rgba(0, 0, 0, 0.039);
	--drop-shadow-outlined-shadow-30: 0 0 1px rgba(0, 0, 0, 0.4), 0 64px 80px rgba(0, 0, 0, 0.09), 0 23.361053466796875px 42.2340202331543px rgba(0, 0, 0, 0.094), 0 11.341358184814453px 26.091463088989258px rgba(0, 0, 0, 0.091), 0 5.559744834899902px 14.917644500732422px rgba(0, 0, 0, 0.08), 0 2.198331356048584px 6.523642539978027px rgba(0, 0, 0, 0.058);
	--drop-shadow-secondary-list: 0 2px 15px rgba(0, 0, 0, 0.06);
	--drop-shadow-shadow-1: 0 6px 12px rgba(0, 0, 0, 0.2);
	--drop-shadow-survey-builder-item: 0 2px 15px rgba(0, 0, 0, 0.06);
	--editor-content-code-block-box-background: #F8F9FA;
	--editor-content-comment-indicator: #FDE8BB;
	--editor-content-comment-indicator-active: #FCD378;
	--editor-content-horizontal-rule: rgba(0, 0, 0, 0.1);
	--editor-content-inline-code-box-background: rgba(0, 0, 0, 0.05);
	--editor-content-insight: rgba(0, 255, 56, 0.24);
	--editor-content-insight-ai: rgba(32, 0, 227, 0.11);
	--editor-content-insight-ai-active: rgba(32, 20, 227, 0.31);
	--editor-content-insight-active: rgba(5, 250, 0, 0.5);
	--editor-content-mention-box-of-current-user: rgba(32, 0, 227, 0.11);
	--editor-content-mention-box-of-other-user: rgba(0, 123, 255, 0.09);
	--editor-content-mention-box-of-user-with-no-access: rgba(122, 122, 122, 0.17);
	--editor-content-quote-indicator: rgba(203, 203, 203, 0.5);
	--editor-content-spell-check-red-line: #FF0000;
	--editor-content-table-lines: #999999;
	--editor-content-text-placeholder-box-background: #FAFAFA;
	--editor-text-formatting-bold: #292929;
	--editor-text-formatting-heading: #292929;
	--editor-text-formatting-italic: #292929;
	--editor-text-formatting-list-bullet: #292929;
	--editor-text-formatting-list-enumerator: #292929;
	--files-panel-dragged-item-background: #1298DB;
	--files-panel-panel-root-outline: #1298DB;
	--files-panel-renaming-input-outline: #42D2FF;
	--focus-focus-base-outline-1: #0098B3;
	--focus-focus-base-outline-2: rgba(20, 170, 245, 0.5);
	--focus-focus-error-outline-1: #BD3100;
	--focus-focus-error-outline-2: rgba(214, 57, 0, 0.5);
	--focus-focus-warning-outline-1: #D68300;
	--focus-focus-warning-outline-2: rgba(255, 155, 0, 0.5);
	--graph-item-active: #007BFF;
	--graph-item-pinned-1: #B2B2B2;
	--graph-item-pinned-2: #FF6161;
	--graph-item-pinned-3: #0AC794;
	--graph-item-pinned-4: #FFBE33;
	--graph-item-pinned-5: #BD41FB;
	--graph-item-pinned-6: #FF7E4D;
	--graph-item-pinned-7: #4DEAFF;
	--guide-button-background: #FFFFFF;
	--guide-button-background-active: #EBEBEB;
	--guide-button-background-hover: #F5F5F5;
	--guide-button-content: #1F0053;
	--guide-container-background: #1F0053;
	--guide-content: #FFFFFF;
	--indicator-unread: #FF0000;
	--input-box-background: #FAFAFA;
	--input-box-background-invalid: #FDF5F6;
	--input-box-outline: #C8C8C8;
	--input-box-outline-focus: #14AAF5;
	--input-box-outline-invalid: #D0021B;
	--label-green-background: #00FB37;
	--label-green-content: #005909;
	--label-grey-background: #E0E0E0;
	--label-grey-content: #191919;
	--label-yellow-background: #FBE200;
	--label-yellow-content: #1B0A00;
	--media-embed-button-background: rgba(0, 0, 0, 0.3);
	--media-embed-button-icon: rgba(255, 255, 255, 0.8);
	--media-embed-button-icon-hover: #FFFFFF;
	--media-embed-button-text: #FFFFFF;
	--media-embed-container-outline-focus: #14AAF5;
	--media-embed-container-outline-hover: #89D4FA;
	--password-strength-indicator-level-0: #E5E5E5;
	--password-strength-indicator-level-1: #D0021B;
	--password-strength-indicator-level-2-3: #EB6C00;
	--password-strength-indicator-level-4: #60B514;
	--radio-button-circle: #1EABEB;
	--radio-button-off-background: #FFFFFF;
	--radio-button-off-outline: #999999;
	--radio-button-on-background: #FFFFFF;
	--radio-button-on-outline: #999999;
	--scrim: rgba(0, 0, 0, 0.5);
	--scrollbar-handle: rgba(139, 139, 139, 0.4);
	--search-match-content-match-background: #FFFE54;
	--search-match-content-match-background-active: #F19B49;
	--search-match-content-match-text: #000000;
	--search-match-scroll-bar-match-indicator-background: #FFFE54;
	--search-match-scroll-bar-match-indicator-outline: #F19B49;
	--search-match-scroll-bar-match-indicator-active: #D06502;
	--select-box-background: #FAFAFA;
	--select-box-background-invalid: #FDF5F6;
	--select-box-outline: #C5C5C5;
	--select-box-outline-focus: #14AAF5;
	--select-box-outline-invalid: #D0021B;
	--separator-primary: #E6E6E6;
	--separator-secondary: #B3B3B3;
	--shadow-tooltip-triangle: rgba(0, 0, 0, 0.2);
	--smarkup-logo-icon-arrow: linear-gradient(180deg, #FFC900 0%, #FF9200 100%);
	--smarkup-logo-icon-s: #404040;
	--smarkup-logo-rectangle-background: #FFFFFF;
	--smarkup-logo-rectangle-outline: #D6D6D6;
	--survey-background: #FFFFFF;
	--survey-icon: #989998;
	--survey-icon-hover: #000000;
	--survey-input: #F4F4F4;
	--survey-outline: #000000;
	--survey-powered-by: #B2B2B2;
	--survey-powered-by-hover: #5A5A5A;
	--survey-send-button-background: #000000;
	--survey-send-button-content: #FFFFFF;
	--survey-shadow: 0 0 1px rgba(0, 0, 0, 0.4), 0 27px 42px rgba(0, 0, 0, 0.08), 0 9.27587890625px 19.785350799560547px rgba(0, 0, 0, 0.076), 0 4.723095893859863px 11.670555114746094px rgba(0, 0, 0, 0.069), 0 2.2491250038146973px 6.129580497741699px rgba(0, 0, 0, 0.058), 0 0.8091866970062256px 2.203927993774414px rgba(0, 0, 0, 0.039);
	--survey-text-ai: #000000;
	--survey-text-placeholder: #B2B2B2;
	--survey-text-user: #000000;
	--survey-user-bubble: #F4F4F4;
	--survey-user-bubble-hover: #EAEAEA;
	--survey-user-bubble-outline: #DADADA;
	--switch-background-monochrome: #6C6C6C;
	--switch-background-off: #CDCECD;
	--switch-background-on: #7ED321;
	--switch-circle: #FFFFFF;
	--tag-primary-background: #FFFFFF;
	--tag-primary-content-primary: #292929;
	--tag-primary-content-secondary: #989998;
	--tag-primary-content-secondary-hover: #000000;
	--tag-primary-outline: #CCCCCC;
	--tag-secondary-background: #F3F3F3;
	--tag-secondary-background-hover: #E5E5E5;
	--tag-warning-background: #FDF7EA;
	--tag-warning-content: #612800;
	--tag-warning-outline: #EDB431;
	--tooltip-background: #1C2027;
	--tooltip-content-primary: #FFFFFF;
	--tooltip-content-secondary: #ABABAB;
	--user-current-user-avatar: #14AAF5;
	--user-current-user-caret: #000000;
	--user-current-user-selection: rgba(158, 208, 255, 0.5);
	--user-current-user-selection-caret: #66B4FF;
	--user-current-user-selection-inactive: rgba(219, 219, 219, 0.5);
	--user-current-user-selection-inactive-caret: #CDCDCD;
	--user-user-1-avatar: #FF6161;
	--user-user-1-caret: #FF9C9C;
	--user-user-1-selection: rgba(255, 158, 158, 0.5);
	--user-user-2-avatar: #584DFF;
	--user-user-2-caret: #4D43E4;
	--user-user-2-selection: rgba(164, 158, 255, 0.5);
	--user-user-3-avatar: #FF4DE3;
	--user-user-3-caret: #F200CC;
	--user-user-3-selection: rgba(255, 158, 239, 0.5);
	--user-user-4-avatar: #0AC794;
	--user-user-4-caret: #00CE97;
	--user-user-4-selection: rgba(158, 255, 229, 0.5);
	--user-user-5-avatar: #FFBE33;
	--user-user-5-caret: #FFAF00;
	--user-user-5-selection: rgba(255, 224, 158, 0.5);
	--user-user-6-avatar: #BD41FB;
	--user-user-6-caret: #B11EFA;
	--user-user-6-selection: rgba(223, 158, 255, 0.5);
	--user-user-7-avatar: #FF7E4D;
	--user-user-7-caret: #FF6B33;
	--user-user-7-selection: rgba(255, 184, 158, 0.5);
	--user-user-name-text: #000000;
	--windows-shell-button-background-active: #D7D7D7;
	--windows-shell-button-background-hover: #F2F2F2;
	--windows-shell-button-close-background-active: #EE7C7B;
	--windows-shell-button-close-background-hover: #D43230;
	--windows-shell-button-close-foreground-hover: #FFFFFF;
	--windows-shell-button-foreground: #7A7B7A;
	--windows-shell-button-foreground-hover: #0A0A0A;
	--windows-shell-button-foreground-inactive: #AEAEAE;
	--windows-shell-button-foreground-on-scrim: #000000;
	--windows-shell-button-foreground-on-scrim-inactive: #3C3C3C;
}