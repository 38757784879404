:root {
	--alert-soft-error-background: #49171C;
	--alert-soft-error-content: #FFB9C1;
	--alert-soft-error-icon-background-hover: #7C2830;
	--alert-soft-error-outline: #FF4D62;
	--alert-soft-info-background: #2B3A41;
	--alert-soft-info-content: #C4E9FF;
	--alert-soft-info-icon-background-hover: #49636F;
	--alert-soft-info-outline: #2D9EE0;
	--alert-soft-success-background: #405938;
	--alert-soft-success-content: #D4FFA8;
	--alert-soft-success-icon-background-hover: #5B7D4F;
	--alert-soft-success-outline: #6ABF14;
	--alert-soft-warning-background: #4D4127;
	--alert-soft-warning-content: #FFE4A6;
	--alert-soft-warning-icon-background-hover: #76643C;
	--alert-soft-warning-outline: #EDB431;
	--alert-vivid-content: #FFFFFF;
	--alert-vivid-error-background: #D0021B;
	--alert-vivid-generic-background: #FFFFFF;
	--alert-vivid-generic-content: #000000;
	--alert-vivid-info-background: #14AAF5;
	--alert-vivid-success-background: #60B514;
	--alert-vivid-warning-background: #EB6C00;
	--background-mention-of-you-notification: #2F2938;
	--background-new-activity: #2D2B31;
	--background-primary: #222022;
	--background-primary-base-1-active: #3F3A40;
	--background-primary-base-1-disabled: #818181;
	--background-primary-base-1-hover: #312E34;
	--background-primary-comment-1-active: #3D372B;
	--background-primary-inverted: #1482CD;
	--background-secondary: #302C30;
	--background-secondary-base-1-hover: #525252;
	--background-selected: #133251;
	--base-icon-disabled: #5C5C5C;
	--base-icon-primary-active: #14A6F5;
	--base-icon-secondary: #989998;
	--base-icon-secondary-hover: #FFFFFF;
	--base-text-code: #FF2D61;
	--base-text-green: #60B503;
	--base-text-inverted-primary: #FFFFFF;
	--base-text-inverted-secondary: rgba(255, 255, 255, 0.7);
	--base-text-link: #55A7FF;
	--base-text-mention-current-user: #D355FF;
	--base-text-mention-no-access: #B3B3B3;
	--base-text-orange: #FF800B;
	--base-text-primary: #F8F8F2;
	--base-text-purple: #A635FF;
	--base-text-red: #FF4D62;
	--base-text-secondary: #B0B0B0;
	--base-text-secondary-hover: #FFFFFF;
	--base-text-text-placeholder: #B2B2B2;
	--button-danger-background: #D83A00;
	--button-danger-background-active: #BD3300;
	--button-danger-background-hover: #CC3700;
	--button-danger-content-primary: #FFFFFF;
	--button-danger-content-secondary: #FFFFFF;
	--button-danger-outline: rgba(0, 0, 0, 0);
	--button-disabled-background: #2C2C2C;
	--button-disabled-content: #656565;
	--button-formatting-background-hover: #2E2C30;
	--button-icon-background: rgba(0, 0, 0, 0);
	--button-icon-background-active: rgba(255, 255, 255, 0.15);
	--button-icon-background-hover: rgba(255, 255, 255, 0.08);
	--button-icon-content: #989998;
	--button-icon-content-hover: #FFFFFF;
	--button-primary-background: #28262A;
	--button-primary-background-active: #1C1A1E;
	--button-primary-background-hover: #313131;
	--button-primary-content-primary: #FFFFFF;
	--button-primary-content-secondary: #B8B8B8;
	--button-primary-outline: #494949;
	--button-secondary-background: #14AAF5;
	--button-secondary-background-active: #13A1E8;
	--button-secondary-background-hover: #18A8F0;
	--button-secondary-content-primary: #FFFFFF;
	--button-secondary-content-secondary: #FFFFFF;
	--button-secondary-outline: rgba(0, 0, 0, 0);
	--button-upgrade-background: linear-gradient(180deg, #FFA100 0%, #FF9200 100%);
	--button-upgrade-background-active: linear-gradient(180deg, #E69100 0%, #E68300 100%);
	--button-upgrade-background-hover: linear-gradient(180deg, #F59A00 0%, #F58C00 100%);
	--button-upgrade-content-primary: #FFFFFF;
	--button-upgrade-outline: rgba(241, 143, 0, 0);
	--button-warning-background: #F18F00;
	--button-warning-background-active: #D67F00;
	--button-warning-background-hover: #E68800;
	--button-warning-content-primary: #FFFFFF;
	--button-warning-content-secondary: #FFFFFF;
	--button-warning-outline: rgba(0, 0, 0, 0);
	--checkbox-check: #FFFFFF;
	--checkbox-off-background: rgba(255, 255, 255, 0);
	--checkbox-off-outline: #999999;
	--checkbox-on-background: #1EABEB;
	--checkbox-on-outline: #1EABEB;
	--chip-unread-background: #BB1414;
	--chip-unread-content: #FFFFFF;
	--chip-unread-outline: rgba(0, 0, 0, 0);
	--diff-checker-highlight-addition: #7EFF93;
	--diff-checker-highlight-removal: #FF7E7E;
	--drop-shadow-outlined-shadow-10: 0 0 1px rgba(255, 255, 255, 0.8), 0 5px 13px rgba(0, 0, 0, 0.4);
	--drop-shadow-outlined-shadow-20: 0 0 1px rgba(255, 255, 255, 0.8), 0 8px 22px rgba(0, 0, 0, 0.28);
	--drop-shadow-outlined-shadow-30: 0 0 1px rgba(255, 255, 255, 0.7), 0 8px 22px rgba(0, 0, 0, 0.3);
	--drop-shadow-secondary-list: 0 2px 15px rgba(0, 0, 0, 0.56);
	--drop-shadow-shadow-1: 0 6px 12px rgba(0, 0, 0, 0.2);
	--drop-shadow-survey-builder-item: 0 3px 15px rgba(0, 0, 0, 0.46);
	--editor-content-code-block-box-background: #37353A;
	--editor-content-comment-indicator: #645534;
	--editor-content-comment-indicator-active: #9A6E0C;
	--editor-content-horizontal-rule: rgba(255, 255, 255, 0.2);
	--editor-content-inline-code-box-background: rgba(255, 255, 255, 0.1);
	--editor-content-insight: rgba(0, 255, 56, 0.26);
	--editor-content-insight-ai: rgba(117, 70, 218, 0.49);
	--editor-content-insight-ai-active: #6732FF;
	--editor-content-insight-active: rgba(5, 250, 0, 0.5);
	--editor-content-mention-box-of-current-user: rgba(101, 77, 255, 0.22);
	--editor-content-mention-box-of-other-user: rgba(0, 123, 255, 0.25);
	--editor-content-mention-box-of-user-with-no-access: rgba(174, 174, 174, 0.17);
	--editor-content-quote-indicator: rgba(203, 203, 203, 0.5);
	--editor-content-spell-check-red-line: #FF0000;
	--editor-content-table-lines: #686868;
	--editor-content-text-placeholder-box-background: #393A35;
	--editor-text-formatting-bold: #FDD7B4;
	--editor-text-formatting-heading: #FDD7B4;
	--editor-text-formatting-italic: #FFFFFF;
	--editor-text-formatting-list-bullet: #FFFFFF;
	--editor-text-formatting-list-enumerator: #FFFFFF;
	--files-panel-dragged-item-background: #1298DB;
	--files-panel-panel-root-outline: #1298DB;
	--files-panel-renaming-input-outline: #42D2FF;
	--focus-focus-base-outline-1: #0098B3;
	--focus-focus-base-outline-2: rgba(20, 170, 245, 0.5);
	--focus-focus-error-outline-1: #BD3100;
	--focus-focus-error-outline-2: rgba(214, 57, 0, 0.5);
	--focus-focus-warning-outline-1: #D68300;
	--focus-focus-warning-outline-2: rgba(255, 155, 0, 0.5);
	--graph-item-active: #007BFF;
	--graph-item-pinned-1: #B2B2B2;
	--graph-item-pinned-2: #FF6161;
	--graph-item-pinned-3: #0AC794;
	--graph-item-pinned-4: #FFBE33;
	--graph-item-pinned-5: #BD41FB;
	--graph-item-pinned-6: #FF7E4D;
	--graph-item-pinned-7: #4DEAFF;
	--guide-button-background: #FFFFFF;
	--guide-button-background-active: #EBEBEB;
	--guide-button-background-hover: #F5F5F5;
	--guide-button-content: #1F0053;
	--guide-container-background: #1F0053;
	--guide-content: #FFFFFF;
	--indicator-unread: #EB3C3C;
	--input-box-background: #1E1D20;
	--input-box-background-invalid: #320A0F;
	--input-box-outline: #404040;
	--input-box-outline-focus: #14AAF5;
	--input-box-outline-invalid: #FF3A53;
	--label-green-background: #00FB37;
	--label-green-content: #005909;
	--label-grey-background: #A2A2A2;
	--label-grey-content: #101010;
	--label-yellow-background: #FBE200;
	--label-yellow-content: #1B0A00;
	--media-embed-button-background: rgba(0, 0, 0, 0.3);
	--media-embed-button-icon: rgba(255, 255, 255, 0.8);
	--media-embed-button-icon-hover: #FFFFFF;
	--media-embed-button-text: #FFFFFF;
	--media-embed-container-outline-focus: #14AAF5;
	--media-embed-container-outline-hover: #095379;
	--password-strength-indicator-level-0: #545454;
	--password-strength-indicator-level-1: #FF4D62;
	--password-strength-indicator-level-2-3: #EB6C00;
	--password-strength-indicator-level-4: #60B514;
	--radio-button-circle: #1EABEB;
	--radio-button-off-background: #282923;
	--radio-button-off-outline: #999999;
	--radio-button-on-background: #0B5475;
	--radio-button-on-outline: #999999;
	--scrim: rgba(0, 0, 0, 0.5);
	--scrollbar-handle: rgba(179, 179, 179, 0.4);
	--search-match-content-match-background: #FFFE54;
	--search-match-content-match-background-active: #F19B49;
	--search-match-content-match-text: #000000;
	--search-match-scroll-bar-match-indicator-background: #FFFE54;
	--search-match-scroll-bar-match-indicator-outline: #F19B49;
	--search-match-scroll-bar-match-indicator-active: #D06502;
	--select-box-background: #1E1D20;
	--select-box-background-invalid: #320A0F;
	--select-box-outline: #404040;
	--select-box-outline-focus: #14AAF5;
	--select-box-outline-invalid: #FF4D62;
	--separator-primary: #3D3B40;
	--separator-secondary: #767676;
	--shadow-tooltip-triangle: rgba(255, 255, 255, 0.2);
	--smarkup-logo-icon-arrow: linear-gradient(180deg, #6B6B6B 0%, #5C5C5C 100%);
	--smarkup-logo-icon-s: linear-gradient(180deg, #6B6B6B 0%, #5C5C5C 100%);
	--smarkup-logo-rectangle-background: linear-gradient(180deg, #383933 0%, #31322C 100%);
	--smarkup-logo-rectangle-outline: rgba(214, 214, 214, 0.09);
	--survey-background: #222022;
	--survey-icon: #989998;
	--survey-icon-hover: #FFFFFF;
	--survey-input: #2F2F2F;
	--survey-outline: #FFFFFF;
	--survey-powered-by: #B2B2B2;
	--survey-powered-by-hover: #FFFFFF;
	--survey-send-button-background: #FFFFFF;
	--survey-send-button-content: #000000;
	--survey-shadow: 0 0 1px rgba(255, 255, 255, 0.7), 0 8px 22px rgba(0, 0, 0, 0.3);
	--survey-text-ai: #FFFFFF;
	--survey-text-placeholder: #B2B2B2;
	--survey-text-user: #FFFFFF;
	--survey-user-bubble: #2F2F2F;
	--survey-user-bubble-hover: #393939;
	--survey-user-bubble-outline: #5C5C5C;
	--switch-background-monochrome: #6F6F6F;
	--switch-background-off: #727472;
	--switch-background-on: #7ED321;
	--switch-circle: #FFFFFF;
	--tag-primary-background: #28262C;
	--tag-primary-content-primary: #FFFFFF;
	--tag-primary-content-secondary: #989998;
	--tag-primary-content-secondary-hover: #000000;
	--tag-primary-outline: #6C6C6C;
	--tag-secondary-background: #3F3A40;
	--tag-secondary-background-hover: #4E494F;
	--tag-warning-background: #755000;
	--tag-warning-content: #FFFFFF;
	--tag-warning-outline: #D5A63B;
	--tooltip-background: #1B1A1E;
	--tooltip-content-primary: #FFFFFF;
	--tooltip-content-secondary: #ABABAB;
	--user-current-user-avatar: #14AAF5;
	--user-current-user-caret: #FFFFFF;
	--user-current-user-selection: rgba(34, 131, 225, 0.5);
	--user-current-user-selection-caret: #66B4FF;
	--user-current-user-selection-inactive: rgba(219, 219, 219, 0.15);
	--user-current-user-selection-inactive-caret: #CDCDCD;
	--user-user-1-avatar: #FF6161;
	--user-user-1-caret: #F33939;
	--user-user-1-selection: rgba(175, 78, 78, 0.5);
	--user-user-2-avatar: #5044FF;
	--user-user-2-caret: #7C73FF;
	--user-user-2-selection: rgba(94, 88, 185, 0.5);
	--user-user-3-avatar: #FF4DE3;
	--user-user-3-caret: #F200CC;
	--user-user-3-selection: rgba(195, 98, 179, 0.5);
	--user-user-4-avatar: #0AC794;
	--user-user-4-caret: #00CE97;
	--user-user-4-selection: rgba(48, 145, 119, 0.5);
	--user-user-5-avatar: #FFAF02;
	--user-user-5-caret: #FFAF00;
	--user-user-5-selection: rgba(145, 114, 48, 0.5);
	--user-user-6-avatar: #BD41FB;
	--user-user-6-caret: #B11EFA;
	--user-user-6-selection: rgba(143, 78, 175, 0.5);
	--user-user-7-avatar: #FF7E4D;
	--user-user-7-caret: #FF6B33;
	--user-user-7-selection: rgba(175, 104, 78, 0.5);
	--user-user-name-text: #FFFFFF;
	--windows-shell-button-background-active: #1F1F1F;
	--windows-shell-button-background-hover: #393A34;
	--windows-shell-button-close-background-active: #EB6462;
	--windows-shell-button-close-background-hover: #D43230;
	--windows-shell-button-close-foreground-hover: #FFFFFF;
	--windows-shell-button-foreground: #A4A4A4;
	--windows-shell-button-foreground-hover: #FFFFFF;
	--windows-shell-button-foreground-inactive: #939393;
	--windows-shell-button-foreground-on-scrim: #9A9A9A;
	--windows-shell-button-foreground-on-scrim-inactive: #727272;
}