/* Webfont: Lato-Regular */@font-face {
  font-family: 'lato';
  src: url('/fonts/Lato-Regular.woff2') format('woff2'), /* Modern Browsers */
  url('/fonts/Lato-Regular.woff') format('woff'), /* Modern Browsers */
  url('/fonts/Lato-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
}

/* Webfont: Lato-Black */@font-face {
  font-family: 'lato';
  src: url('/fonts/Lato-Black.woff2') format('woff2'), /* Modern Browsers */
  url('/fonts/Lato-Black.woff') format('woff'), /* Modern Browsers */
  url('/fonts/Lato-Black.ttf') format('truetype');
  font-style: normal;
  font-weight: 900;
}

/* Webfont: Lato-BlackItalic */@font-face {
  font-family: 'lato';
  src: url('/fonts/Lato-BlackItalic.woff2') format('woff2'), /* Modern Browsers */
  url('/fonts/Lato-BlackItalic.woff') format('woff'), /* Modern Browsers */
  url('/fonts/Lato-BlackItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 900;
}

/* Webfont: Lato-Bold */@font-face {
  font-family: 'lato';
  src: url('/fonts/Lato-Bold.woff2') format('woff2'), /* Modern Browsers */
  url('/fonts/Lato-Bold.woff') format('woff'), /* Modern Browsers */
  url('/fonts/Lato-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: 700;
}

/* Webfont: Lato-BoldItalic */@font-face {
  font-family: 'lato';
  src: url('/fonts/Lato-BoldItalic.woff2') format('woff2'), /* Modern Browsers */
  url('/fonts/Lato-BoldItalic.woff') format('woff'), /* Modern Browsers */
  url('/fonts/Lato-BoldItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 700;
}

/* Webfont: Lato-Hairline */@font-face {
  font-family: 'lato';
  src: url('/fonts/Lato-Hairline.woff2') format('woff2'), /* Modern Browsers */
  url('/fonts/Lato-Hairline.woff') format('woff'), /* Modern Browsers */
  url('/fonts/Lato-Hairline.ttf') format('truetype');
  font-style: normal;
  font-weight: 100;
}

/* Webfont: Lato-HairlineItalic */@font-face {
  font-family: 'lato';
  src: url('/fonts/Lato-HairlineItalic.woff2') format('woff2'), /* Modern Browsers */
  url('/fonts/Lato-HairlineItalic.woff') format('woff'), /* Modern Browsers */
  url('/fonts/Lato-HairlineItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 100;
}

/* Webfont: Lato-Heavy */@font-face {
  font-family: 'lato';
  src: url('/fonts/Lato-Heavy.woff2') format('woff2'), /* Modern Browsers */
  url('/fonts/Lato-Heavy.woff') format('woff'), /* Modern Browsers */
  url('/fonts/Lato-Heavy.ttf') format('truetype');
  font-style: normal;
  font-weight: 800;
}

/* Webfont: Lato-HeavyItalic */@font-face {
  font-family: 'lato';
  src: url('/fonts/Lato-HeavyItalic.woff2') format('woff2'), /* Modern Browsers */
  url('/fonts/Lato-HeavyItalic.woff') format('woff'), /* Modern Browsers */
  url('/fonts/Lato-HeavyItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 800;
}

/* Webfont: Lato-Italic */@font-face {
  font-family: 'lato';
  src: url('/fonts/Lato-Italic.woff2') format('woff2'), /* Modern Browsers */
  url('/fonts/Lato-Italic.woff') format('woff'), /* Modern Browsers */
  url('/fonts/Lato-Italic.ttf') format('truetype');
  font-style: italic;
  font-weight: 400;
}

/* Webfont: Lato-Light */@font-face {
  font-family: 'lato';
  src: url('/fonts/Lato-Light.woff2') format('woff2'), /* Modern Browsers */
  url('/fonts/Lato-Light.woff') format('woff'), /* Modern Browsers */
  url('/fonts/Lato-Light.ttf') format('truetype');
  font-style: normal;
  font-weight: 300;
}

/* Webfont: Lato-LightItalic */@font-face {
  font-family: 'lato';
  src: url('/fonts/Lato-LightItalic.woff2') format('woff2'), /* Modern Browsers */
  url('/fonts/Lato-LightItalic.woff') format('woff'), /* Modern Browsers */
  url('/fonts/Lato-LightItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 300;
}

/* Webfont: Lato-Medium */@font-face {
  font-family: 'lato';
  src: url('/fonts/Lato-Medium.woff2') format('woff2'), /* Modern Browsers */
  url('/fonts/Lato-Medium.woff') format('woff'), /* Modern Browsers */
  url('/fonts/Lato-Medium.ttf') format('truetype');
  font-style: normal;
  font-weight: 500;
}

/* Webfont: Lato-MediumItalic */@font-face {
  font-family: 'lato';
  src: url('/fonts/Lato-MediumItalic.woff2') format('woff2'), /* Modern Browsers */
  url('/fonts/Lato-MediumItalic.woff') format('woff'), /* Modern Browsers */
  url('/fonts/Lato-MediumItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 500;
}

/* Webfont: Lato-Semibold */@font-face {
  font-family: 'lato';
  src: url('/fonts/Lato-Semibold.woff2') format('woff2'), /* Modern Browsers */
  url('/fonts/Lato-Semibold.woff') format('woff'), /* Modern Browsers */
  url('/fonts/Lato-Semibold.ttf') format('truetype');
  font-style: normal;
  font-weight: 600;
}

/* Webfont: Lato-SemiboldItalic */@font-face {
  font-family: 'lato';
  src: url('/fonts/Lato-SemiboldItalic.woff2') format('woff2'), /* Modern Browsers */
  url('/fonts/Lato-SemiboldItalic.woff') format('woff'), /* Modern Browsers */
  url('/fonts/Lato-SemiboldItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 600;
}

/* Webfont: Lato-Thin */@font-face {
  font-family: 'lato';
  src: url('/fonts/Lato-Thin.woff2') format('woff2'), /* Modern Browsers */
  url('/fonts/Lato-Thin.woff') format('woff'), /* Modern Browsers */
  url('/fonts/Lato-Thin.ttf') format('truetype');
  font-style: normal;
  font-weight: 200;
}

/* Webfont: Lato-ThinItalic */@font-face {
  font-family: 'lato';
  src: url('/fonts/Lato-ThinItalic.woff2') format('woff2'), /* Modern Browsers */
  url('/fonts/Lato-ThinItalic.woff') format('woff'), /* Modern Browsers */
  url('/fonts/Lato-ThinItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 200;
}

